import CRMAuthRoutes from '../../../../vendor/z5internet/crm-ui/src/resources/assets/js/Routes/authRoutes';

import asyncComponent from 'Demora/AsyncComponent';

const LinkTracker = asyncComponent(() =>
	import(
		/* webpackChunkName: 'LTAuthHome' */ '../../js/components/LinkTracker'
	)
);

export default CRMAuthRoutes.concat([
	{
		path: '/home/linktracker/:tab1?/:tab2?',
		component: LinkTracker,
	},
]);
