import _ from 'lodash';

import plurals from '../Utils/plurals';

export default (t, ts) => {
	let data = (state = {}, action) => {
		switch (action.type) {
			case 'STORE_CRM_DATA':
				if (action.data.entities[ts]) {
					let properties = action.data.entities.properties;

					Object.values(action.data.entities[ts]).map(dd => {
						if (!dd.properties) {
							return;
						}

						dd.properties.map(property_id => {
							let property = properties[property_id];

							if (dd[property.property_type]) {
								dd[property.property_type].push(property);
							} else {
								dd[property.property_type] = [property];
							}
						});
					});

					return _.merge({}, state, action.data.entities[ts]);
				}

				break;

			case 'ADD_COMPANY_TO_CONTACT':
				if (ts === 'contacts') {
					state[action.data.contactId].companies.data.push(
						action.data.company.id,
					);

					return _.merge({}, state);
				}

				break;

			case 'ADD_CONTACT_TO_COMPANY':
				if (ts === 'companies') {
					state[action.data.companyId].contacts.data.push(
						action.data.contact.id,
					);

					return _.merge({}, state);
				}

				break;

			case 'ASSIGN_DEAL_TO_COMPANY':
				if (ts === 'companies') {
					state[action.data.companyId].deals.data.push(action.data.deal.id);

					return _.merge({}, state);
				}

				break;

			case 'ASSIGN_DEAL_TO_CONTACT':
				if (ts === 'contacts') {
					state[action.data.contactId].deals.data.push(action.data.deal.id);

					return _.merge({}, state);
				}

				break;

			case 'DELETE_ITEM':
				let type = action.objectType;
				let id = action.id;

				if (ts == plurals.toPlural[type]) {
					state[id].deleted_at = 1;

					return _.merge({}, state);
				}
		}

		return state;
	};

	let ids = (state = {}, action, data) => {
		switch (action.type) {
			case 'STORE_CRM_DATA':
				return Object.keys(data);
		}

		return state;
	};

	let labels;

	if (['properties'].indexOf(ts) != -1) {
		labels = (state = {}, action, data) => {
			switch (action.type) {
				case 'STORE_CRM_DATA':
					let update = false;

					if (action.data.entities[ts]) {
						Object.keys(action.data.entities[ts]).map(g1 => {
							let property = data[g1];

							let pl = plurals.toPlural[property.object_type];

							if (!state[pl]) {
								update = true;

								state[pl] = {};
							}

							state[pl][property.property_type] = 1;
						});

						if (update) {
							return _.merge({}, state);
						}
					}

				default:
					return state;
			}
		};
	}

	return (state = {}, action) => {
		let out = {
			data: data(state.data, action),
		};

		out.ids = ids(state.ids, action, out.data);

		if (labels) {
			out.labels = labels(state.labels, action, out.data);
		}

		//		if (byObjectType) {
		//			out.byObjectType = byObjectType(state.byObjectType, action, out.data);
		//		}

		return out;
	};
};
