import extend from 'Demora/extend';

import Companies from '../js/Store/Companies';

import Contacts from '../js/Store/Contacts';

import Deals from '../js/Store/Deals';

import Notes from '../js/Store/Notes';

import Owners from '../js/Store/Owners';

import Properties from '../js/Store/Properties';

import Tasks from '../js/Store/Tasks';

let combined = {
	companies: Companies,
	contacts: Contacts,
	deals: Deals,
	notes: Notes,
	owners: Owners,
	properties: Properties,
	tasks: Tasks,
};

export default combined;
