import asyncComponent from 'Demora/AsyncComponent';

let Companies = asyncComponent(() =>
	import(/* webpackChunkName: "CRMCompanies" */ './Companies'),
);

let Company = asyncComponent(() =>
	import(/* webpackChunkName: "CRMCompany" */ './Company'),
);

export default [
	{
		path: '/home/crm/companies/:companyId?/:tab?',
		component: Companies,
	},
	{
		path: '/home/crm/company/:companyId/:tab?',
		component: Company,
	},
];
