import asyncComponent from 'Demora/AsyncComponent';

let Contacts = asyncComponent(() =>
	import(/* webpackChunkName: "CRMContacts" */ './Contacts'),
);

let Contact = asyncComponent(() =>
	import(/* webpackChunkName: "CRMContact" */ './Contact'),
);

export default [
	{
		path: '/home/crm/contacts/:contactId?/:tab?',
		component: Contacts,
	},
	{
		path: '/home/crm/contact/:contactId/:tab?',
		component: Contact,
	},
];
