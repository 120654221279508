import AppAuthRoutes from 'resources/assets/react-app/routes/authRoutes';

import asyncComponent from 'Demora/AsyncComponent';

let Settings = asyncComponent(() =>
	import(/* webpackChunkName: "prot-auth-settings" */ './Settings/Settings')
);

const routes = [
	{
		path: '/home',
		component: asyncComponent(() =>
			import(
				/* webpackChunkName: "prot-auth-authHome" */ 'resources/assets/react-app/components/AuthHome'
			)
		),
	},
	{
		path: '/settings',
		component: Settings,
	},
	{
		path: '/settings/profile',
		component: Settings,
	},
	{
		path: '/settings/security',
		component: Settings,
	},
	{
		path: '/settings/changeEmail',
		component: Settings,
	},
	{
		path: '/settings/multiAccounts',
		component: Settings,
	},
	{
		path: '/settings/multiAccounts/:team',
		component: Settings,
	},
	{
		path: '/settings/multiAccounts/:team/:teamTab',
		component: Settings,
	},
	{
		path: '/settings/payments',
		component: Settings,
	},
	{
		path: '/getStarted/:page?',
		component: asyncComponent(() =>
			import(
				/* webpackChunkName: "prot-auth-getStarted" */ 'resources/assets/react-app/components/GetStarted'
			)
		),
	},
	{
		path: '/setupExistingUser',
		component: asyncComponent(() =>
			import(
				/* webpackChunkName: "prot-auth-SetupExistingUser" */ './SetupExistingUser/SetupExistingUser'
			)
		),
	},
	{
		path: '/referrals',
		component: asyncComponent(() =>
			import(
				/* webpackChunkName: "prot-auth-referrals" */ './Referrals/Referrals'
			)
		),
	},
	{
		path: '/admin/:tab?/:uid?/:tab2?',
		component: asyncComponent(() =>
			import(/* webpackChunkName: "prot-admin-admin" */ './Admin/admin')
		),
	},
	{
		path: '/notifications',
		component: asyncComponent(() =>
			import(
				/* webpackChunkName: "prot-auth-notifications" */ './Notifications/Notifications'
			)
		),
	},
	{
		path: '/billing',
		component: asyncComponent(() =>
			import(/* webpackChunkName: "prot-auth-billing" */ './Billing/Billing')
		),
	},
].concat(AppAuthRoutes);

for (let i = 0; i < routes.length; i++) {
	routes[i].auth = true;
}

export default routes;
