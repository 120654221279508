import asyncComponent from 'Demora/AsyncComponent';

let Deals = asyncComponent(() =>
	import(/* webpackChunkName: "CRMDeals" */ './Deals'),
);

let Deal = asyncComponent(() =>
	import(/* webpackChunkName: "CRMDeal" */ './Deal'),
);

export default [
	{
		path: '/home/crm/deals/:dealId?/:tab?',
		component: Deals,
	},
	{
		path: '/home/crm/deal/:dealId/:tab?',
		component: Deal,
	},
];
