import CompanyRoutes from '../../js/Components/Companies/CompanyRoutes';

import ContactRoutes from '../../js/Components/Contacts/ContactRoutes';

import DealRoutes from '../../js/Components/Deals/DealRoutes';

//import Email from '../../js/Components/Messages/src/App';

export default CompanyRoutes.concat(ContactRoutes)
	.concat(DealRoutes)
	.concat([]);
