import AppOpenRoutes from 'resources/assets/react-app/routes/openRoutes';

import asyncComponent from 'Demora/AsyncComponent';

const routes = [
	{
		path: '/',
		component: asyncComponent(() =>
			import(
				/* webpackChunkName: "home" */ 'resources/assets/react-app/components/HomePage'
			)
		),
	},
	{
		path: '/password/reset',
		component: asyncComponent(() =>
			import(/* webpackChunkName: "passwordreset" */ './ForgottenPassword')
		),
	},
	{
		path: '/password/reset/:token',
		component: asyncComponent(() =>
			import(/* webpackChunkName: "passwordreset" */ './ResetPassword')
		),
	},
	{
		path: '/terms',
		component: asyncComponent(() =>
			import(
				/* webpackChunkName: "terms" */ 'resources/assets/react-app/components/Terms'
			)
		),
	},
	{
		path: '/privacy',
		component: asyncComponent(() =>
			import(
				/* webpackChunkName: "privacy" */ 'resources/assets/react-app/components/Privacy'
			)
		),
	},
	{
		path: '/upgrade/complete',
		component: asyncComponent(() =>
			import(
				/* webpackChunkName: "upgradecomplete" */ 'resources/assets/react-app/components/UpgradeComplete'
			)
		),
	},
	{
		path: '/login',
		component: asyncComponent(() =>
			import(/* webpackChunkName: "login" */ './login/_index')
		),
	},
	{
		path: '/login/multi',
		component: asyncComponent(() =>
			import(/* webpackChunkName: "prot-auth-billing" */ './login/TeamSelector')
		),
	},
	{
		path: '/logout',
		component: asyncComponent(() =>
			import(/* webpackChunkName: "logout" */ './Logout')
		),
	},
	{
		path: '/signup',
		component: asyncComponent(() =>
			import(/* webpackChunkName: "signup" */ './Signup')
		),
	},
	{
		path: '/contact',
		component: asyncComponent(() =>
			import(/* webpackChunkName: "contact" */ './Contact')
		),
	},
	{
		path: '/setup',
		component: asyncComponent(() =>
			import(/* webpackChunkName: "setup" */ './Setup')
		),
	},
	{
		path: '/setup/:id/:code',
		component: asyncComponent(() =>
			import(/* webpackChunkName: "setup" */ './Setup')
		),
	},
].concat(AppOpenRoutes);

export default routes;
