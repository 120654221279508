const pl = {
	company: 'companies',
	contact: 'contacts',
	deal: 'deals',
	task: 'tasks',
	message: 'messages',
	note: 'notes',
};

const array_flip = trans => {
	var key,
		tmp_ar = {};

	for (key in trans) {
		if (trans.hasOwnProperty(key)) {
			tmp_ar[trans[key]] = key;
		}
	}

	return tmp_ar;
};

export default {
	toPlural: pl,
	fromPlural: array_flip(pl),
};
