import Reducers from '../../../vendor/z5internet/crm-ui/src/resources/assets/js/reducers';

Reducers.CRMconfig = (state = {}, action) => {
	state.dontshowCompanies = true;
	state.dontshowDeals = true;

	return state;
};

export default Reducers;
